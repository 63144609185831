import React from "react";

export default function NewspaperForm(props) {
    return (
        <>
            <div className="form-group mb-4">
                <label htmlFor="PublicationTypeID">Publication Type</label>
                <input
                    type="number"
                    id={"PublicationTypeID"}
                    onChange={props.onEdit}
                    value={"4"}
                    className={"form-control"}
                    disabled
                    hidden
                />
                <input
                    type="text"
                    value={"Newspaper/Magazine"}
                    className={"form-control"}
                    disabled
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="WorkTitle">Publication Title</label>
                <input
                    type="text"
                    id={"WorkTitle"}
                    onChange={props.onEdit}
                    value={props.data.WorkTitle}
                    className={"form-control"}
                    placeholder={"Paper Title"}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="Authors">Author</label>
                <input
                    type="text"
                    id={"Authors"}
                    onChange={props.onEdit}
                    value={props.data.Authors}
                    className={"form-control"}
                    placeholder={"Authors"}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="PublishedYear">Publication Date</label>
                <input
                    type="date"
                    id={"PublishedYear"}
                    onChange={props.onEdit}
                    value={props.data.PublishedYear}
                    className={"form-control"}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="PaperTitle">Paper / Magazine Name</label>
                <input
                    type="text"
                    id={"PaperTitle"}
                    onChange={props.onEdit}
                    value={props.data.PaperTitle}
                    className={"form-control"}
                    placeholder={"Paper / Magazine Name"}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="OnlineURL">Online Link</label>
                <input
                    type="text"
                    id={"OnlineURL"}
                    onChange={props.onEdit}
                    value={props.data.OnlineURL}
                    className={"form-control"}
                    placeholder={"Online URL"}
                />
            </div>

            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                    </span>
                </button>
            </div>
        </>
    )
}